import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import Backdrop from "@components/Backdrop"
import Header from "./Header"
import Footer from "./Footer"
import Item from "./Item"
import CarSelector from "./CarSelector"
import ProSeries from "./ProSeries"
import Products from "./Products"
import SubMenu from "./SubMenu"
import VerticalSpace from "@components/VerticalSpace"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import { DatoResponsiveImage } from "@dato/types"
import { MobileOnly } from "@utils/MediaQueries"
import Link from "next/link"

const openProps = css`
  opacity: 1;
  transform: translateX(0);
  transition: opacity ${timings.quick} ${cubicBezier},
    transform ${timings.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    opacity: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: opacity ${timings.medium} ${cubicBezier},
      transform ${timings.quick} ${cubicBezier};
    will-change: opacity, transform;
    width: calc(100% - 40px);
    background-color: ${colors.snow};
    cursor: default;

    ${up("laptop")} {
      display: none;
    }

    ${isOpen ? openProps : ""}
  `}
`

const ModuleToggle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-left: 10px;

  span {
    margin: 0;
    padding-bottom: 16px;
  }

  a {
    transition: 0.3s all ease;
    color: #aaa;

    &.active,
    &:hover {
      font-weight: 600;

      &.automotive {
        color: ${colors.red};
      }

      &.powersports {
        color: ${colors.alt};
      }
    }
  }
`

const activeProps = css`
  color: ${colors.snow};
  transition: 0.6s ease-out;
`

const Button = styled.div<{ active }>`
  ${({ active }) => css`
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 10px;
    padding: 0;
    outline: none;
    background: none;
    color: ${colors.snow};
    padding-bottom: 10px;

    ${active ? activeProps : ""}

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  `}
`

interface MenuMobileProps {
  isOpen?: boolean
  data: {
    proSeriesHeader: string
    proSeriesBody: string
    proSeriesCategories: Array<{
      name: string
      slug: string
      powersportsSeries?: string
    }>
    mainSectionLabel: string
    mainSectionLinks: Array<{
      label: string
      link: string
    }>
    sideSectionLabel: string
    sideSectionLinks: Array<{
      label: string
      link: string
    }>
    footerRightColumnLabel: string
    footerRightColumnLinks: Array<{
      label: string
      link: string
    }>
    footerLeftColumnLabel: string
    footerLeftColumnLinks: Array<{
      label: string
      link: string
    }>
    cars: Array<{
      make: {
        name: string
        slug: string
      }
      model: {
        name: string
        slug: string
        image: {
          responsiveImage: DatoResponsiveImage
        }
        imageWidth?: number
        imageHeight?: number
        mobileImageWidth?: number
        mobileImageHeight?: number
      }
      trim?: {
        name: string
        slug: string
        image: {
          responsiveImage: DatoResponsiveImage
        }
        imageWidth?: number
        imageHeight?: number
        mobileImageWidth?: number
        mobileImageHeight?: number
      }
      years: string
    }>
    makeModelOrder: Array<{
      make: { name: string; slug: string }
      models: Array<{ name: string; slug: string }>
    }>
    powersportsSeatCategories: Array<{
      name: string
      slug: string
      powersportsSeries?: string
    }>
    powersportsArmorCategories: Array<{
      name: string
      slug: string
      powersportsSeries?: string
    }>
  }
  menuStateOnOpen: MenuStates | undefined
  onRequestClose: () => void
  module?: string
}

export enum MenuStates {
  Picker = "picker",
  ProSeries = "proSeries",
  Products = "products",
  Explore = "explore",
  Support = "support",
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  isOpen,
  data,
  menuStateOnOpen,
  onRequestClose,
  module = "automotive",
}) => {
  const [menuState, setMenuState] = React.useState<MenuStates | undefined>(
    undefined
  )

  const clearMenuState = () => {
    setMenuState(undefined)
  }

  React.useEffect(() => {
    if (!isOpen) {
      clearMenuState()
    } else {
      if (!!menuStateOnOpen) {
        setMenuState(menuStateOnOpen)
      }
    }
  }, [isOpen])

  const menuRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: menuRef,
    isOpen,
    onRequestClose,
  })

  console.log(data)

  return (
    <MobileOnly>
      <Backdrop isOpen={isOpen}>
        <Container id="mobileMenu" ref={menuRef} isOpen={isOpen}>
          <Header onRequestClose={onRequestClose} />
          <VerticalSpace height={2} />
          <ModuleToggle>
            <Button onClick={() => {}} active={false}>
              <Link
                className={
                  module == "automotive" ? "active automotive" : "automotive"
                }
                href="/"
              >
                Automotive
              </Link>
            </Button>
            <span>/</span>
            <Button onClick={() => {}} active={false}>
              <Link
                className={
                  module == "powersports" ? "active powersports" : "powersports"
                }
                href="/powersports/"
              >
                Powersports
              </Link>
            </Button>
          </ModuleToggle>
          <VerticalSpace height={2} />
          <Item
            header
            label="Shop by Vehicle"
            onClick={() => {
              setMenuState(MenuStates.Picker)
            }}
          />
          {module == "automotive" ? (
            <>
              <Item
                header
                label="Universal"
                onClick={() => {
                  setMenuState(MenuStates.ProSeries)
                }}
              />
              <Item
                header
                label="Configurator"
                onClick={() => {}}
                link={"/configurator"}
              />
            </>
          ) : (
            <>
              <Item
                header
                label="Products"
                onClick={() => {
                  setMenuState(MenuStates.ProSeries)
                }}
              />
            </>
          )}
          <VerticalSpace height={40} />
          {module == "automotive" ? (
            <Item
              label="All Products"
              onClick={() => {
                setMenuState(MenuStates.Products)
              }}
            />
          ) : (
            ""
          )}
          <Item
            label="Explore"
            onClick={() => {
              setMenuState(MenuStates.Explore)
            }}
          />
          <Item
            label="Support"
            onClick={() => {
              setMenuState(MenuStates.Support)
            }}
          />
          <Footer onRequestClose={onRequestClose} />
          <CarSelector
            isOpen={menuState === MenuStates.Picker}
            cars={data.cars}
            module={module}
            makeModelOrder={data.makeModelOrder}
            onRequestClose={clearMenuState}
            onRequestCloseMenu={onRequestClose}
          />
          <ProSeries
            isOpen={menuState === MenuStates.ProSeries}
            header={
              module == "automotive"
                ? data.proSeriesHeader
                : "Powersports Products"
            }
            body={data.proSeriesBody}
            items={
              module == "automotive"
                ? data.proSeriesCategories
                : [
                    ...data.powersportsSeatCategories,
                    ...data.powersportsArmorCategories,
                  ]
            }
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
            module={module}
          />
          <Products
            isOpen={menuState === MenuStates.Products}
            mainSectionLabel={data.mainSectionLabel}
            mainSectionLinks={data.mainSectionLinks}
            sideSectionLabel={data.sideSectionLabel}
            sideSectionLinks={data.sideSectionLinks}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
          <SubMenu
            isOpen={menuState === MenuStates.Explore}
            label={data.footerRightColumnLabel}
            items={data.footerRightColumnLinks}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
          <SubMenu
            isOpen={menuState === MenuStates.Support}
            label={data.footerLeftColumnLabel}
            items={data.footerLeftColumnLinks}
            onRequestBack={clearMenuState}
            onRequestClose={onRequestClose}
          />
        </Container>
      </Backdrop>
    </MobileOnly>
  )
}

export default MenuMobile
