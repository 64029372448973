import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import { columns14, columnsInMaxWidth } from "@constants/layout"
import { Image } from "react-datocms"
import Link from "next/link"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { bodyBold, body, subtext, subtextBold, h2 } from "@constants/typography"
import { columns } from "@constants/layout"
import { Info } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import VerticalSpace from "@components/VerticalSpace"
import { DatoResponsiveImage } from "@dato/types"
import SelectInput, {
  Container as SelectInputContainer,
} from "@components/inputs/SelectInput"
import {
  getCarsMakeModelYearUrl,
  getCarsMakeModelYearTrimUrl,
} from "@utils/urls"
import _ from "lodash"
import { useRouter } from "next/router"

const Container = styled.div`
  width: 100%;
  padding: 36px ${columns(4)};
  background-color: ${colors.snow};
`

const Body = styled.div<{
  bgColor: string
}>`
  ${({ bgColor }) => css`
    display: block;
    font-family: "Jost", sans-serif;
    background-color: ${bgColor ? bgColor : `#000000`};

    ${down("tablet")} {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 30px 0;
    }
  `}

  button {
    background: #ff1c24;
    color: #fff;
    min-width: 150px;
    text-transform: uppercase;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.6s ease-out;

    ${down("tablet")} {
      width: 100%;
      padding: 15px;
    }

    &:hover {
      color: #ff1c24;
      background: #fff;
    }

    &:disabled {
      background: ${colors.mediumGray};
      cursor: not-allowed;
    }
  }
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;

  ${up("laptop")} {
    flex-direction: row;
    padding: 0;
  }

  ${down("tablet")} {
    padding: 10px 40px;
  }
`

const Filters = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${up("laptop")} {
    flex-direction: row;
    padding: 0;
  }

  ${down("tablet")} {
    display: block;
    width: 100%;
  }
`

const Filter = styled.div<{ disabled?: boolean }>`
  min-width: 150px;
  font-family: "Jost", sans-serif;
  flex-grow: 0;
  margin-right: 20px;

  ${down("tablet")} {
    margin-right: 0;
    margin-bottom: 10px;
  }

  ${({ disabled }) => css`
    pointer-events: ${disabled ? "none" : "all"};

    [class*="SelectInput__Container"] {
      background-color: ${disabled ? colors.mediumGray : colors.lightestGray};
    }
  `}

  [class*="SelectInput__Container"] {
    padding: 0 8px;
    height: 36px;

    ${down("tablet")} {
      height: 50px;
    }
  }

  [class*="MuiInput-formControl"] {
    margin-top: 16px;
    height: 16px;
    font-size: 14px;

    ${down("tablet")} {
      margin-top: 22px;
    }
  }

  [class*="MuiInputLabel-root"] {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 16px;
    transform: translate(0, 10px) scale(1);

    ${down("tablet")} {
      transform: translate(0, 18px) scale(1);
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 4px) scale(0.6) !important;
  }

  .MuiNativeSelect-icon {
    top: 0px;
  }
`

const Label = styled.div<{
  fontColor: string
}>`
  ${({ fontColor }) => css`
    color: ${fontColor ? fontColor : `#ffffff`};
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;

    ${down("tablet")} {
      margin-bottom: 0;
      text-shadow: 0 0 10px black;
    }

    > div {
      font-size: 15px;

      ${down("tablet")} {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
    }

    > h1 {
      font-size: 25px;
      line-height: 25px;
      font-weight: 400;
      margin: 0;

      ${down("tablet")} {
        display: none;
      }
    }
  `}
`

const Footer = styled.div`
  ${subtext}
  font-weight: normal;
  align-items: center;
  justify-content: start;
  display: flex;
  color: ${colors.slateDark};

  ${IconWrapper} {
    opacity: 0.4;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    border: 1.5px solid ${colors.slateLight};
    border-radius: 50%;
  }
`

interface CarSelectorProps {
  isOpen: boolean
  cars: Array<{
    make: {
      name: string
      slug: string
    }
    model: {
      name: string
      slug: string
      image: {
        responsiveImage: DatoResponsiveImage
      }
      imageWidth?: number
      imageHeight?: number
      mobileImageWidth?: number
      mobileImageHeight?: number
    }
    trim?: {
      name: string
      slug: string
      image: {
        responsiveImage: DatoResponsiveImage
      }
      imageWidth?: number
      imageHeight?: number
      mobileImageWidth?: number
      mobileImageHeight?: number
    }
    years: string
    module: string
  }>
  makeModelOrder: Array<{
    make: { name: string; slug: string }
    models: Array<{ name: string; slug: string }>
  }>
  resetPicker?: boolean
  onResetPicker?: () => void
  onRequestCloseMenu?: () => void
  module?: string
}

const CarSelector: React.FC<CarSelectorProps> = ({
  isOpen,
  cars,
  makeModelOrder = {},
  resetPicker,
  onResetPicker,
  onRequestCloseMenu,
  module = "automotive",
}) => {
  const router = useRouter()
  const layoutData = require("@data/layoutData")

  cars = cars.filter((car) => car.module == module)

  const [make, setMake] = React.useState<string | "">("")
  const [model, setModel] = React.useState<string | "">("")
  const [trim, setTrim] = React.useState<string | "">("")
  const [year, setYear] = React.useState<string | "">("")

  const setCar = (event) => {
    if (onRequestCloseMenu) {
      onRequestCloseMenu()
    }
    window.scrollTo({ top: 20, behavior: "smooth" })

    if (trim) {
      router.push(getCarsMakeModelYearTrimUrl(make, model, year, trim))
    } else {
      router.push(getCarsMakeModelYearUrl(make, model, year))
    }
  }

  const makes = _.sortBy(
    _.uniqBy(
      cars.map(({ make }) => ({ label: make.name, value: make.slug })).sort(),
      "value"
    ),
    ["value"]
  ) as Array<any>

  const [models, setModels] = React.useState<Array<any>>([])

  const [trims, setTrims] = React.useState<Array<any>>([])

  const [years, setYears] = React.useState<Array<any>>([])

  const onSelectMake = (make) => {
    const filteredModels = _.sortBy(
      _.uniqBy(
        cars
          .filter((car) => car.make.slug === make)
          .map(({ model }) => ({ label: model.name, value: model.slug })),
        "value"
      ),
      ["value"]
    )

    setMake(make)
    setModels(filteredModels)

    setMake(make)
    setModel("")
    setTrim("")
    setYear("")
    setTrims([])
    setYears([])

    document.getElementById("Model")?.focus()
  }

  const onSelectModel = (model) => {
    if (model.trim() != "") {
      const filteredCars = _.sortBy(
        cars.filter(
          (car) => car.make.slug === make && car.model.slug === model
        ),
        ["slug"]
      )

      if (filteredCars.length > 1) {
        const trims = _.sortBy(
          _.uniqBy(
            filteredCars.map(({ trim }) => ({
              label: trim?.name,
              value: trim?.slug,
            })) as any,
            "value"
          ),
          ["value"]
        ) as any

        setModel(model)
        setTrim("")
        setTrims(trims)
        setYears([])

        document.getElementById("Trim")?.focus()
      } else {
        const thisCar = filteredCars[0]
        const filteredYears = thisCar.years.split(", ").map((year) => ({
          label: year.trim(),
          value: year.trim(),
        }))

        setModel(model)
        setYear("")
        setTrim("")
        setTrims([])
        setYears(filteredYears)

        document.getElementById("Year")?.focus()
      }
    } else {
      setModel("")
      setTrims([])
      setYears([])
    }
  }

  const onSelectTrim = (trim) => {
    const thisCar = cars.find((car) => {
      return (
        car.make.slug === make &&
        car.model.slug === model &&
        car.trim?.slug === trim
      )
    })

    if (thisCar) {
      const filteredYears = thisCar.years.split(", ").map((year) => ({
        label: year.trim(),
        value: year.trim(),
      }))

      setTrim(trim)
      setYears(filteredYears)
    }
  }

  const reset = () => {
    setMake("")
    setModel("")
    setTrim("")

    setModels([])
    setTrims([])
    setYears([])
  }

  React.useEffect(() => {
    if (!isOpen || resetPicker) {
      reset()

      if (resetPicker && onResetPicker) {
        onResetPicker()
      }
    }
  }, [isOpen, resetPicker])

  return (
    <Container>
      <Body bgColor={colors.snow}>
        <Label fontColor={colors.obsidian}>
          <div>Shop by vehicle</div>
          <h1>Let's start that project</h1>
        </Label>
        <Content>
          <Filters>
            <Filter>
              <SelectInput
                name="Make"
                label="Make"
                value={make}
                options={makes}
                onChange={(e) => {
                  onSelectMake(e.target.value)
                }}
              />
            </Filter>
            <Filter disabled={!make}>
              <SelectInput
                name={"Model"}
                label={"Model"}
                value={model}
                options={models}
                onChange={(e) => {
                  onSelectModel(e.target.value)
                }}
              />
            </Filter>
            {trims?.length > 0 && (
              <Filter disabled={!make || !model}>
                <SelectInput
                  name={"Trim"}
                  label={"Trim"}
                  value={trim}
                  options={trims}
                  onChange={(e) => {
                    years
                    onSelectTrim(e.target.value)
                  }}
                />
              </Filter>
            )}
            <Filter
              disabled={
                trims?.length > 0 ? !make || !model || !trim : !make || !model
              }
            >
              <SelectInput
                name={"Year"}
                label={"Year"}
                value={year}
                options={years}
                onChange={(e) => {
                  setYear(e.target.value)
                }}
              />
            </Filter>
            <button
              disabled={make == "" || model == "" || year == ""}
              onClick={(e) => {
                setCar(e)
              }}
            >
              Build
            </button>
          </Filters>
        </Content>
      </Body>
      <VerticalSpace height={40} />
      <Footer>
        <IconWrapper>
          <Info />
        </IconWrapper>
        Don't see your vehicle? Give us a call 888-460-0640
      </Footer>
    </Container>
  )
}

export default CarSelector
