import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { body, h6, smallcaps } from "@constants/typography"
import { Facebook, Instagram, YouTube } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"

export interface FooterProps {
  user?: string
  contactPhoneNumber: string
  contactAddress: string
  facebookUrl: string
  instagramUrl: string
  youtubeUrl: string
  desktopFooterLinks: Array<{
    label: string
    links: Array<{
      label: string
      module: {
        name: string
      }
      url: string
      external?: boolean
    }>
  }>
  mobileFooterLinks: Array<{
    label: string
    url: string
    external?: boolean
  }>
  paymentIcons: Array<{ url: string }>
  privacyPolicy: string
  copyrightText: string
  prop65Disclaimer: string
  prop65Link: string
  onShopByVehicle?: (event) => void
  onShopProSeries?: (event) => void
  onShopAllProducts?: (event) => void
  onShopByVehicleMobile?: (event) => void
  onShopProSeriesMobile?: (event) => void
  onShopAllProductsMobile?: (event) => void
  module?: string
}

export const SocialLinks = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translateX(-14px);

  ${up("laptop")} {
    transform: translateX(14px);
  }
`

export const SocialIconWrapper = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;

  ${up("laptop")} {
    transition: background-color ${timings.quick} ${cubicBezier};
    border-radius: 2px;

    &:hover {
      cursor: pointer;
      background-color: ${colors.divider};
    }
  }
`

export const SocialIcons = ({
  facebookUrl,
  instagramUrl,
  youtubeUrl,
}: {
  facebookUrl: string
  instagramUrl: string
  youtubeUrl: string
}) => (
  <SocialLinks>
    <SocialIconWrapper href={instagramUrl} target="_blank">
      <IconWrapper>
        <Instagram />
      </IconWrapper>
    </SocialIconWrapper>
    <SocialIconWrapper href={youtubeUrl} target="_blank">
      <IconWrapper>
        <YouTube />
      </IconWrapper>
    </SocialIconWrapper>
    <SocialIconWrapper href={facebookUrl} target="_blank">
      <IconWrapper>
        <Facebook />
      </IconWrapper>
    </SocialIconWrapper>
  </SocialLinks>
)

export const BoldLink = styled.a`
  ${h6}
  align-items: center;
  display: flex;
  height: 48px;
  margin-bottom: 4px;
  color: ${colors.dirtySnow};

  ${up("laptop")} {
    margin-bottom: 0;
    color: ${colors.snow};

    &:hover {
      cursor: pointer;
    }
  }
`

export const BoldLinkFaded = styled.a`
  opacity: 0.9;

  ${up("laptop")} {
    &:hover {
      cursor: pointer;
    }
  }
`

export const Smallcaps = styled.p`
  ${smallcaps};
  opacity: 0.5;
  margin: 0;
  color: ${colors.snow};
`

export const Warning = styled.div`
  padding: 16px 50px 28px 32px;

  ${up("laptop")} {
    padding: 0 14px 0 18px;
  }
`

export const WarningLink = styled.a`
  ${body};
  opacity: 0.7;
  font-weight: 500;
  color: ${colors.snow};

  ${up("laptop")} {
    &:hover {
      cursor: pointer;
    }
  }
`

export const WarningLinkRedText = styled.span`
  ${body};
  margin-left: 6px;
  font-weight: 500;
  color: ${colors.primary};

  ${up("laptop")} {
    &:hover {
      cursor: pointer;
    }
  }
`
