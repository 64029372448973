import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { timings, cubicBezier } from "@constants/animation"
import { body, smallcaps } from "@constants/typography"
import { Info } from "@components/icons"
import { IconWrapper } from "@components/icons/utils"
import Header from "./Header"
import Item from "./Item"
import VerticalSpace from "@components/VerticalSpace"
import { getProSeriesCategoryUrl } from "@utils/urls"

const openProps = css`
  transform: translateX(0);
  transition: transform ${timings.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    z-index: 3;
    position: absolute;
    top: 0;
    flex-direction: column;
    display: flex;
    opacity: 1;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform ${timings.medium} ${cubicBezier};
    will-change: transform;
    width: 100%;
    height: 100%;
    background-color: ${colors.snow};
    cursor: default;

    ${isOpen ? openProps : ""}
  `}
`

const overlayActiveProps = css`
  opacity: 0.1;
  transition: opacity ${timings.medium} ${cubicBezier};
`
const Overlay = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ${timings.long} ${cubicBezier};
    will-change: opacity;
    height: 100%;
    width: 100%;
    background-color: ${colors.black};
    pointer-events: none;

    ${isVisible ? overlayActiveProps : ""}
  `}
`

const Footer = styled.div`
  margin-top: auto;
  padding-bottom: 40px;
`

const FooterHeader = styled.div`
  ${smallcaps}
  align-items: center;
  display: flex;
  padding: 0 34px;
  color: ${colors.slateDark};

  ${IconWrapper} {
    margin-right: 10px;
  }
`

const FooterBody = styled.div`
  ${body}
  padding: 0 34px;
  color: ${colors.slateDark};
`

interface ProSeriesProps {
  isOpen: boolean
  header: string
  body: string
  items: Array<{
    name: string
    slug: string
  }>
  onRequestBack?: () => void
  onRequestClose: () => void
  module?: string
}

const ProSeries: React.FC<ProSeriesProps> = ({
  isOpen,
  header,
  body,
  items,
  onRequestBack,
  onRequestClose,
  module = "automotive",
}) => {
  return (
    <>
      <Overlay isVisible={isOpen} />
      <Container isOpen={isOpen}>
        <Header
          label={header}
          onRequestBack={onRequestBack}
          onRequestClose={onRequestClose}
        />
        <VerticalSpace height={8} />
        {items.map((item: any, i) => (
          <Item
            key={i}
            label={item.name}
            link={getProSeriesCategoryUrl(
              item.slug,
              module != "automotive" && item.powersportsSeries
                ? item.powersportsSeries.toLowerCase()
                : undefined
            )}
            onClick={onRequestClose}
          />
        ))}
        {module == "automotive" ? (
          <Footer>
            <FooterHeader>
              <IconWrapper>
                <Info />
              </IconWrapper>
              Universal
            </FooterHeader>
            <VerticalSpace height={20} />
            <FooterBody>{body}</FooterBody>
          </Footer>
        ) : (
          ""
        )}
      </Container>
    </>
  )
}

export default ProSeries
