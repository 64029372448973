import React from "react"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"
import Link from "next/link"
import colors from "@constants/colors"
import { columns14, columnsInMaxWidth } from "@constants/layout"
import { body, subtext, smallcaps } from "@constants/typography"
import MaxWidthContainer from "@components/Layout/MaxWidthContainer"
import MaxWidthContent from "@components/Layout/MaxWidthContent"
import VerticalSpace from "@components/VerticalSpace"
import {
  FooterProps,
  SocialIcons,
  BoldLink,
  Smallcaps,
  Warning,
  WarningLink,
  WarningLinkRedText,
} from "./common"

const Container = styled.div`
  display: none;

  ${up("laptop")} {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding: 120px 0 80px;
  }
`

const Row = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
`

const Section = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
`

const EndColumn = styled.div`
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
`

const Column = styled.div<{ columnSize?: number }>`
  ${({ columnSize }) => css`
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    display: flex;

    ${up("laptop")} {
      width: ${columns14(columnSize ?? 2)};
    }
    ${up("maxWidth")} {
      width: calc(${columnsInMaxWidth(columnSize ?? 2)});
    }
  `}
`

const ColumnHeader = styled.div`
  ${smallcaps};
  opacity: 0.5;
  color: ${colors.snow};
  font-size: 11px;
  font-weight: 700;
`

const Subtext = styled.p`
  ${subtext};
  margin: 0;
  color: ${colors.snow};
`

const SubtextFaded = styled(Subtext)`
  opacity: 0.7;
`

const linkProps = css`
  ${body}
  opacity: 0.7;
  line-height: 36px;
  color: ${colors.snow};

  &:hover {
    cursor: pointer;
  }
`

const InternalLink = styled.a`
  ${linkProps}
`

const ExternalLink = styled.a`
  ${linkProps}
`

const PaymentIcons = styled.div`
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  display: flex;
`

const IconWrapper = styled.img<{ width: number; height: number }>`
  ${({ width, height }) => css`
    flex-shrink: 0;
    align-items: center;
    display: flex;
    width: ${width}px;
    height: ${height}px;
    margin-right: 12px;
  `}
`

const StyledWarning = styled(Warning)`
  display: none;

  ${up("laptop")} {
    display: initial;
  }
`

const DesktopFooter: React.FC<FooterProps> = ({
  contactPhoneNumber,
  contactAddress,
  facebookUrl,
  instagramUrl,
  youtubeUrl,
  desktopFooterLinks,
  paymentIcons,
  privacyPolicy,
  copyrightText,
  prop65Disclaimer,
  prop65Link,
  onShopByVehicle,
  onShopProSeries,
  onShopAllProducts,
  module = "automotive",
}) => {
  return (
    <MaxWidthContainer>
      <MaxWidthContent cols={12}>
        <Container>
          <Row style={{ alignItems: "flex-start" }}>
            <Section>
              <Column columnSize={2.5}>
                <ColumnHeader>Shop</ColumnHeader>
                <VerticalSpace height={22} />
                <BoldLink onClick={onShopByVehicle}>Shop by Vehicle</BoldLink>
                <BoldLink onClick={onShopProSeries}>Pro Series</BoldLink>
                <BoldLink onClick={onShopAllProducts}>All Products</BoldLink>
                <BoldLink onClick={() => {}}>
                  <Link href={"/configurator"}>Configurator</Link>
                </BoldLink>
              </Column>
              {desktopFooterLinks.map(({ label, links }) => (
                <Column key={label}>
                  <ColumnHeader>{label}</ColumnHeader>
                  <VerticalSpace height={34} />
                  {links.map(({ label, url, external }) =>
                    external ? (
                      <ExternalLink key={url} href={url} target="_blank">
                        {label}
                      </ExternalLink>
                    ) : (
                      <Link
                        key={url}
                        href={
                          !module || module == "automotive" || module == ""
                            ? url
                            : `/powersports${url}`
                        }
                        passHref
                      >
                        <InternalLink>{label}</InternalLink>
                      </Link>
                    )
                  )}
                </Column>
              ))}
            </Section>
            <Section>
              <EndColumn as="address">
                <Subtext
                  as="a"
                  href={`tel:${contactPhoneNumber}`}
                  target="_blank"
                >
                  {contactPhoneNumber}
                </Subtext>
                <VerticalSpace height={6} />
                <SubtextFaded>{contactAddress}</SubtextFaded>
                <VerticalSpace height={26} />
                <SocialIcons
                  facebookUrl={facebookUrl}
                  instagramUrl={instagramUrl}
                  youtubeUrl={youtubeUrl}
                />
              </EndColumn>
            </Section>
          </Row>
          <Row>
            <Section style={{ alignSelf: "flex-end" }}>
              <PaymentIcons>
                {paymentIcons.map(({ url }) => (
                  <IconWrapper key={url} width={56} height={18} src={url} />
                ))}
              </PaymentIcons>
            </Section>
            <Section style={{ alignItems: "center" }}>
              <EndColumn>
                <Smallcaps as="a" href={privacyPolicy} target="_blank">
                  Privacy Policy
                </Smallcaps>
                <VerticalSpace height={12} />
                <Smallcaps>{copyrightText}</Smallcaps>
                <VerticalSpace height={32} />
                <StyledWarning>
                  <WarningLink href={prop65Link} target="_blank">
                    <span>&#9888;</span> {prop65Disclaimer}{" "}
                    <WarningLinkRedText>{prop65Link}</WarningLinkRedText>
                  </WarningLink>
                </StyledWarning>
              </EndColumn>
            </Section>
          </Row>
        </Container>
      </MaxWidthContent>
    </MaxWidthContainer>
  )
}

export default DesktopFooter
