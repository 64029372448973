import React from "react"
import styled from "styled-components"
import Link from "next/link"
import colors from "@constants/colors"
import { body, bodyBold, smallcaps } from "@constants/typography"
import { columns } from "@constants/layout"
import VerticalSpace from "@components/VerticalSpace"
import _ from "lodash"

const Container = styled.div`
  width: 100%;
  padding: 72px ${columns(4.5)};
  background-color: ${colors.snow};
`

const Section = styled.div``

const Row = styled.div`
  display: flex;
`

const MainColumn = styled.div`
  width: ${columns(1.75)};
`

const FooterColumn = styled.div`
  width: ${columns(2.25)};
`

const Label = styled.div`
  ${smallcaps}
  padding-bottom: 16px;
`

const MainSectionLabel = styled.div`
  width: ${columns(5.25)};
`

const SideSectionLabel = styled.div`
  width: ${columns(1.75)};
`

const ItemBold = styled.div`
  ${bodyBold}
  margin: 16px 0;

  &:hover {
    cursor: pointer;
  }
`

const Item = styled.div`
  ${body}
  margin: 16px 0;

  &:hover {
    cursor: pointer;
  }
`

interface FullMenuProps {
  user?: string
  mainSectionLabel: string
  mainSectionLinks: Array<{
    label: string
    link: string
    module?: {
      name: string
    }
  }>
  sideSectionLabel: string
  sideSectionLinks: Array<{
    label: string
    link: string
  }>
  footerRightColumnLabel: string
  footerRightColumnLinks: Array<{
    label: string
    link: string
  }>
  navbarRightColumnLinks: Array<{
    label: string
    link: string
  }>
  footerLeftColumnLabel: string
  footerLeftColumnLinks: Array<{
    label: string
    link: string
  }>
  onRequestCloseMenu?: () => void
  module?: string
}

const FullMenu: React.FC<FullMenuProps> = ({
  user,
  mainSectionLabel,
  mainSectionLinks,
  sideSectionLabel,
  sideSectionLinks,
  footerRightColumnLabel,
  footerRightColumnLinks,
  navbarRightColumnLinks,
  footerLeftColumnLabel,
  footerLeftColumnLinks,
  onRequestCloseMenu,
  module = "automotive",
}) => {
  console.log(mainSectionLinks)
  const filteredLinks = mainSectionLinks.filter(
    (link) =>
      !module ||
      module.toLowerCase() == link.module?.name.toLowerCase() ||
      link.module?.name.toLowerCase() == "all"
  )

  const chunkedMainSectionLinks = _.chunk(
    filteredLinks,
    filteredLinks.length / 2
  )

  console.log(chunkedMainSectionLinks)

  return (
    <Container>
      <Section>
        <Row>
          <MainSectionLabel>
            <Label>{mainSectionLabel}</Label>
          </MainSectionLabel>
          <SideSectionLabel>
            <Label>{sideSectionLabel}</Label>
          </SideSectionLabel>
        </Row>
        <Row>
          <MainColumn>
            {chunkedMainSectionLinks[0].map((link) => (
              <Link
                key={link.link}
                href={
                  (link.module?.name.toLowerCase() != "all"
                    ? ""
                    : "/" + module) + link.link
                }
                passHref
              >
                <ItemBold onClick={onRequestCloseMenu}>{link.label}</ItemBold>
              </Link>
            ))}
          </MainColumn>
          <MainColumn>
            {chunkedMainSectionLinks[1].map(({ label, link }) => (
              <Link
                key={label}
                href={
                  !module || module == "automotive" || module == ""
                    ? link
                    : `/powersports${link}`
                }
                passHref
              >
                <ItemBold onClick={onRequestCloseMenu}>{label}</ItemBold>
              </Link>
            ))}
          </MainColumn>
          <MainColumn />
          <MainColumn>
            {sideSectionLinks.map(({ label, link }) => (
              <Link
                key={label}
                href={
                  !module || module == "automotive" || module == ""
                    ? link
                    : `/powersports${link}`
                }
                passHref
              >
                <ItemBold onClick={onRequestCloseMenu}>{label}</ItemBold>
              </Link>
            ))}
          </MainColumn>
        </Row>
      </Section>
      <VerticalSpace height={132} />
      <Section>
        <Row>
          <FooterColumn>
            <Label>{footerLeftColumnLabel}</Label>
          </FooterColumn>
          <FooterColumn>
            <Label>{footerRightColumnLabel}</Label>
          </FooterColumn>
          <FooterColumn>
            <Label>Account</Label>
          </FooterColumn>
        </Row>
        <Row>
          <FooterColumn>
            {footerLeftColumnLinks.map(({ label, link }) => (
              <Link
                key={label}
                href={
                  !module || module == "automotive" || module == ""
                    ? link
                    : `/powersports${link}`
                }
                passHref
              >
                <Item onClick={onRequestCloseMenu}>{label}</Item>
              </Link>
            ))}
          </FooterColumn>
          <FooterColumn>
            {footerRightColumnLinks.map(({ label, link }) => (
              <Link
                key={label}
                href={
                  !module || module == "automotive" || module == ""
                    ? link
                    : `/powersports${link}`
                }
                passHref
              >
                <Item onClick={onRequestCloseMenu}>{label}</Item>
              </Link>
            ))}
          </FooterColumn>
        </Row>
      </Section>
    </Container>
  )
}

export default FullMenu
