import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import { body, bodyBold } from "@constants/typography"
import { DESKTOP_HEADER_HEIGHT } from "@constants/measurements"
import { MenuStates } from "./common"
import Link from "next/link"

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  min-width: 400px;
  height: 60px;
  border-bottom: 1px solid ${colors.divider};
  background-color: ${colors.black};

  &.powersports {
    min-width: auto;
  }
`

const ModuleToggle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-left: 10px;

  span {
    margin: 0;
    padding-bottom: 16px;
  }

  a {
    transition: 0.3s all ease;
    border-bottom: 2px solid transparent;
    color: #aaa;

    &.active,
    &:hover {
      font-weight: 600;

      &.automotive {
        color: ${colors.red};
        border-color: ${colors.red};
      }

      &.powersports {
        color: ${colors.alt};
        border-color: ${colors.alt};
      }
    }
  }
`

const Content = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`

const activeProps = css`
  color: ${colors.snow};
  border-bottom: 2px solid ${colors.snow};
  transition: 0.6s ease-out;
`
const Button = styled.div<{ active }>`
  ${({ active }) => css`
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 10px;
    padding: 0;
    outline: none;
    border-bottom: 2px solid ${colors.black};
    background: none;
    color: ${colors.snow};
    padding-bottom: 10px;

    ${active ? activeProps : ""}

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  `}
`

interface HeaderProps {
  menuState?: undefined | MenuStates
  onResetVehicleSelectorMenu?: () => void
  onClickVehicleSelectorMenu?: () => void
  onClickPSSeatsMenu?: () => void
  onClickProSeriesMenu?: () => void
  onClickPSArmorMenu?: () => void
  onClickMainMenu?: () => void
  module?: string
}

const Header: React.FC<HeaderProps> = ({
  menuState,
  onResetVehicleSelectorMenu,
  onClickVehicleSelectorMenu,
  onClickProSeriesMenu,
  onClickPSSeatsMenu,
  onClickPSArmorMenu,
  onClickMainMenu,
  module = "automotive",
}) => {
  return (
    <Container className={module}>
      <Content>
        <ModuleToggle>
          <Button onClick={() => {}} active={false}>
            <Link
              className={
                module == "automotive" ? "active automotive" : "automotive"
              }
              href="/"
            >
              Automotive
            </Link>
          </Button>
          <span>/</span>
          <Button onClick={() => {}} active={false}>
            <Link
              className={
                module == "powersports" ? "active powersports" : "powersports"
              }
              href="/powersports/"
            >
              Powersports
            </Link>
          </Button>
        </ModuleToggle>
        {module == "automotive" ? (
          <>
            <Button
              onClick={() => {
                if (
                  onResetVehicleSelectorMenu &&
                  menuState === MenuStates.Picker
                ) {
                  onResetVehicleSelectorMenu()
                }
                if (
                  onClickVehicleSelectorMenu &&
                  menuState !== MenuStates.Picker
                ) {
                  onClickVehicleSelectorMenu()
                }
              }}
              active={menuState && menuState === MenuStates.Picker}
            >
              Shop by Vehicle
            </Button>
            <Button
              onClick={() => {
                if (onClickProSeriesMenu) {
                  onClickProSeriesMenu()
                }
              }}
              active={menuState && menuState === MenuStates.ProSeries}
            >
              Seats
            </Button>
            <Button
              onClick={() => {
                if (onClickMainMenu) {
                  onClickMainMenu()
                }
              }}
              active={menuState && menuState === MenuStates.FullMenu}
            >
              Full Menu
            </Button>
            <Button onClick={() => {}} active={false}>
              <Link
                href={
                  (!module || module == "" || module == "automotive"
                    ? ""
                    : "/" + module) + "/configurator"
                }
              >
                Configurator
              </Link>
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                if (
                  onResetVehicleSelectorMenu &&
                  menuState === MenuStates.Picker
                ) {
                  onResetVehicleSelectorMenu()
                }
                if (
                  onClickVehicleSelectorMenu &&
                  menuState !== MenuStates.Picker
                ) {
                  onClickVehicleSelectorMenu()
                }
              }}
              active={menuState && menuState === MenuStates.Picker}
            >
              Shop by Vehicle
            </Button>
            <Button
              onClick={() => {
                if (onClickPSSeatsMenu) {
                  onClickPSSeatsMenu()
                }
              }}
              active={menuState && menuState === MenuStates.PSSeat}
            >
              Seats
            </Button>
            <Button
              onClick={() => {
                if (onClickPSArmorMenu) {
                  onClickPSArmorMenu()
                }
              }}
              active={menuState && menuState === MenuStates.PSArmor}
            >
              Exterior
            </Button>
          </>
        )}
      </Content>
    </Container>
  )
}

export default Header
