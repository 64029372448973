import React from "react"
import styled, { css } from "styled-components"
import { up, down } from "styled-breakpoints"
import Link from "next/link"
import measurements from "@constants/measurements"
import { Logo } from "./index"

export const buttonProps = css`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

export const Button = styled.button`
  ${buttonProps}
`

export const IconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: ${measurements.ICON_DIMENSIONS};
  width: ${measurements.ICON_DIMENSIONS};
`

export const LogoWrapper = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 76px;
  height: 30px;

  &.powersports {
    width: auto;
  }

  ${up("laptop")} {
    cursor: pointer;
    width: 125px;
    height: 50px;

    &.powersports {
      width: auto;
    }
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const LogoComponent: React.FC<any> = ({
  href,
  src,
  module = "automotive",
  ...rest
}) => {
  return (
    <Link
      href={
        !module || module == "automotive" || module == "" ? "/" : `/${module}/`
      }
      passHref
    >
      <LogoWrapper className={module} {...rest}>
        {src ? <Image src={src} /> : <Logo />}
      </LogoWrapper>
    </Link>
  )
}
