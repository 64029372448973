import React from "react"
import MobilePromoBanner from "./MobilePromoBanner"
import DesktopPromoBanner from "./DesktopPromoBanner"
import { PromoBannerProps } from "./common"

const PromoBanner: React.FC<PromoBannerProps> = ({
  promoBannerLinks,
  promos,
  module = "automotive",
}) => {
  return (
    <>
      <MobilePromoBanner
        promoBannerLinks={promoBannerLinks}
        promos={promos}
        module={module}
      />
      <DesktopPromoBanner
        module={module}
        promoBannerLinks={promoBannerLinks}
        promos={promos}
      />
    </>
  )
}

export default PromoBanner
