import Backdrop from "@components/Backdrop"
import Cart from "@components/Cart/hoc/withStore"
import CartToast from "@components/CartToast/hoc/withStore"
import Footer from "@components/Footer"
import { FooterProps } from "@components/Footer/common"
import MainMenu from "@components/MenuDesktop/MainMenu"
import ProSeriesMenu from "@components/MenuDesktop/ProSeriesMenu"
import { MenuStates as DesktopMenuStates } from "@components/MenuDesktop/common"
import MenuMobile, {
  MenuStates as MobileMenuStates,
} from "@components/MenuMobile"
import Header from "@components/NavbarHeader"
import CarSelector from "@components/NavbarHeader/components/MenuDesktop/CarSelector"
import NewsletterForm from "@components/NewsletterForm"
import PromoBanner from "@components/PromoBanner"
import { PromoBannerProps } from "@components/PromoBanner/common"
import Search from "@components/Search"
import UserSlideout from "@components/UserSlideout"
import { cubicBezier, timings } from "@constants/animation"
import {
  DESKTOP_HEADER_HEIGHT,
  DESKTOP_PROMO_HEIGHT,
} from "@constants/measurements"
import { COOKIE_POLICY_KEY, MODULE } from "@constants/storageKeys"
import { selectCartQuantity } from "@redux/selectors"
import { Dispatch, RootState } from "@redux/store"
import ExitIntent from "@utils/ExitIntent"
import { delay } from "@utils/index"
import { getLocalStorage, writeLocalStorage } from "@utils/storage"
import { getSearchBySkuUrl, getSearchUrl } from "@utils/urls"
import { useRouter } from "next/router"
import React from "react"
import { connect } from "react-redux"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import colors from "@constants/colors"

const Container = styled.div<{ hideFooterOnLaptop?: boolean }>`
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 100vh;

  ${up("laptop")} {
    ${({ hideFooterOnLaptop }) => css`
      footer {
        display: ${hideFooterOnLaptop ? "none" : "initial"};
      }
    `}
  }
`

const PromoBannerWrapper = styled.div<{ collapse: boolean }>`
  ${({ collapse }) => css`
    z-index: 10;
    overflow: hidden;
    transition: height ${timings.quick} ${cubicBezier};
    width: 100%;
    height: ${collapse ? 0 : 49}px;

    ${up("laptop")} {
      z-index: 1001;
      overflow: ${collapse ? "hidden" : "visibile"};
      width: 100%;
      height: ${collapse ? 0 : 36}px;
    }
  `}
`

const MenuWrapper = styled.div``

const Main = styled.main`
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  width: 100%;
  height: 100%;
`

const OpenKeyboard = styled.textarea`
  position: fixed;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
`

const Layout = ({
  layoutData,
  customerAccessToken,
  customerDataFetched,
  checkoutDataFetched,
  customerData,
  checkoutId,
  items,
  total,
  subtotal,
  productCount,
  fetchCustomerData,
  fetchCheckout,
  headerBoxShadow = true,
  hideFooterOnLaptop,
  children,
  module = "automotive",
}: any) => {
  const { globalConfiguration, menuConfiguration, allCars } = layoutData

  const router = useRouter()

  const goToAccount = () => {
    router.push("/account")
  }

  const [cart, setCart] = React.useState<boolean>(false)

  const openCart = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    setCart(true)
  }

  const closeCart = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    setCart(false)
  }

  const [userSlideout, setUserSlideout] = React.useState(false)

  const openUserSlideout = () => {
    setUserSlideout(true)
  }

  const closeUserSlideout = () => {
    setUserSlideout(false)
  }

  React.useEffect(() => {
    if (!customerDataFetched) {
      fetchCustomerData(customerAccessToken)
    }
  }, [customerAccessToken])

  React.useEffect(() => {
    if (!checkoutDataFetched && checkoutId) {
      fetchCheckout(checkoutId)
    }
  }, [])

  //Newsletter triggers
  React.useEffect(() => {
    let fired = false

    if (
      document.cookie.indexOf("modal_seen=true") < 0 &&
      document.cookie.indexOf("newsletter_submitted=true") < 0
    ) {
      setTimeout(function () {
        const removeExitIntent = ExitIntent({
          threshold: 30,
          eventThrottle: 100,
          onExitIntent: () => {
            if (!fired) {
              fired = true
              setShowNewsletter(true)
            }
          },
        })

        window.onscroll = function () {
          if (!fired) {
            let half = document.body.clientHeight / 2

            if (
              document.body.scrollTop >= half ||
              document.documentElement.scrollTop >= half
            ) {
              fired = true
              setShowNewsletter(true)
            }
          }
        }

        return () => {
          removeExitIntent()
        }
      }, 5000)
    }

    return
  }, [])

  const [storageLoaded, setStorageLoaded] = React.useState<boolean>(false)
  const [showBanner, setShowBanner] = React.useState<boolean>(false)

  const acceptCookies = () => {
    writeLocalStorage(COOKIE_POLICY_KEY, true)
    setShowBanner(false)
  }

  const declineCookies = () => {
    writeLocalStorage(COOKIE_POLICY_KEY, false)
    setShowBanner(false)
  }

  React.useEffect(() => {
    if (!storageLoaded) {
      const cookiePolicy = getLocalStorage(COOKIE_POLICY_KEY)

      if (typeof cookiePolicy !== "boolean") {
        setShowBanner(true)
      }

      setStorageLoaded(true)
    }
  }, [])

  const headerRef = React.useRef<any>(null)
  const menuRef = React.useRef<any>(null)

  const [menuState, setMenuState] = React.useState<
    DesktopMenuStates | undefined
  >(undefined)

  const [mobileMenuState, setMobileMenuState] = React.useState<
    MobileMenuStates | undefined
  >(undefined)

  const [menu, setMenu] = React.useState<boolean>(false)
  const [mobileMenu, setMobileMenu] = React.useState<boolean>(false)

  const openMenu = () => {
    setMenu(true)
  }

  const closeMenu = () => {
    setMenu(false)
    setMenuState(undefined)
  }

  const openMobileMenu = (event) => {
    event.stopPropagation()

    setMobileMenu(true)
  }

  const closeMobileMenu = () => {
    setMobileMenu(false)
    setMenuState(undefined)
  }

  const openMobileMenuPicker = (event) => {
    setMobileMenuState(MobileMenuStates.Picker)
    openMobileMenu(event)
  }

  const openMobileMenuProSeries = (event) => {
    setMobileMenuState(MobileMenuStates.ProSeries)
    openMobileMenu(event)
  }

  const openMobileMenuAllProducts = (event) => {
    setMobileMenuState(MobileMenuStates.Products)
    openMobileMenu(event)
  }

  React.useEffect(() => {
    if (!mobileMenu) {
      setMobileMenuState(undefined)
    }
  }, [mobileMenu])

  const [resetPickerState, setResetPickerState] = React.useState<boolean>(false)

  const triggerResetPickerState = () => {
    setResetPickerState(true)
  }

  const onResetPickerState = () => {
    setResetPickerState(false)
  }

  const openVehicleSelectorMenu = () => {
    setMenu(true)
    setMenuState(DesktopMenuStates.Picker)
  }

  const openProSeriesMenu = () => {
    setMenu(true)
    setMenuState(DesktopMenuStates.ProSeries)
  }

  const openMainMenu = () => {
    setMenu(true)
    setMenuState(DesktopMenuStates.FullMenu)
  }

  const openPSSeatsMenu = () => {
    setMenu(true)
    setMenuState(DesktopMenuStates.PSSeat)
  }

  const openPSArmorMenu = () => {
    setMenu(true)
    setMenuState(DesktopMenuStates.PSArmor)
  }

  // useModalBackgroundClose({
  //   modalRef: menuRef,
  //   excludeRefs: [headerRef],
  //   isOpen: menu,
  //   onRequestClose: closeMenu,
  // })

  const [search, searchSearch] = React.useState<boolean>(false)

  const openSearch = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    searchSearch(true)
  }

  const closeSearch = () => {
    searchSearch(false)
  }

  const onSearch = (query: string, searchBySku?: boolean) => {
    const fn = searchBySku ? getSearchBySkuUrl : getSearchUrl
    router.push(fn(query))
  }

  const waitForScroll = async (fn) => {
    let done = false
    let maxIterations = 12
    let currentIteration = 1

    while (!done) {
      if (currentIteration <= maxIterations) {
        await delay(100)
        currentIteration++

        if (window?.scrollY < 200) {
          done = true
        }
      } else {
        done = true
      }
    }

    fn && fn()
  }

  const promoBannerProps: PromoBannerProps = React.useMemo(
    () => ({
      promoBannerLinks: globalConfiguration?.promoBannerLinks || [],
      promos: globalConfiguration?.promos || [],
    }),
    [globalConfiguration]
  )

  const cookieBannerProps = React.useMemo(
    () => ({
      header: globalConfiguration?.cookieBannerHeader || "",
      body: globalConfiguration?.cookieBannerBody || "",
      acceptText: globalConfiguration?.cookieBannerAcceptText || "",
      declineText: globalConfiguration?.cookieBannerDeclineText || "",
    }),
    [globalConfiguration]
  )

  const footerProps: FooterProps = React.useMemo(
    () => ({
      contactEmail: globalConfiguration?.contactEmail || "",
      contactPhoneNumber: globalConfiguration?.contactPhoneNumber || "",
      contactAddress: globalConfiguration?.contactAddress || "",
      facebookUrl: globalConfiguration?.facebookUrl || "",
      instagramUrl: globalConfiguration?.instagramUrl || "",
      youtubeUrl: globalConfiguration?.youtubeUrl || "",
      desktopFooterLinks: globalConfiguration?.desktopFooterLinks || [],
      mobileFooterLinks: globalConfiguration?.mobileFooterLinks || [],
      paymentIcons: globalConfiguration?.paymentIcons || [],
      privacyPolicy: globalConfiguration?.privacyPolicy.slug || "",
      copyrightText: globalConfiguration?.copyrightText || "",
      prop65Disclaimer: globalConfiguration?.prop65Disclaimer || "",
      prop65Link: globalConfiguration?.prop65Link || "",
      onShopByVehicle: () => {
        window?.scrollTo({ top: 0, behavior: "smooth" })
        waitForScroll(openVehicleSelectorMenu)
      },
      onShopProSeries: () => {
        window?.scrollTo({ top: 0, behavior: "smooth" })
        waitForScroll(openProSeriesMenu)
      },
      onShopAllProducts: () => {
        window?.scrollTo({ top: 0, behavior: "smooth" })
        waitForScroll(openMainMenu)
      },
      onShopByVehicleMobile: (event) => {
        openMobileMenuPicker(event)
      },
      onShopProSeriesMobile: (event) => {
        openMobileMenuProSeries(event)
      },
      onShopAllProductsMobile: (event) => {
        openMobileMenuAllProducts(event)
      },
      module: module,
    }),
    [globalConfiguration]
  )

  const menuData = { ...menuConfiguration, cars: allCars }
  const [showNewsletter, setShowNewsletter] = React.useState<boolean>(false)

  const logo = globalConfiguration.logos.filter(
    (logo) => logo.module.name.toLowerCase() == module.toLowerCase()
  )[0]

  return (
    <Container hideFooterOnLaptop={hideFooterOnLaptop}>
      <PromoBannerWrapper collapse={!globalConfiguration?.displayPromoBanner}>
        <PromoBanner module={module} {...promoBannerProps} />
      </PromoBannerWrapper>
      <Header
        headerRef={headerRef}
        logoSrc={logo ? logo.logo.url : globalConfiguration.logo.url}
        user={customerData?.displayName || undefined}
        cartCount={productCount}
        menuState={menuState}
        boxShadow={headerBoxShadow}
        onClickSearch={openSearch}
        onClickCart={openCart}
        onResetVehicleSelectorMenu={triggerResetPickerState}
        onClickVehicleSelectorMenu={openVehicleSelectorMenu}
        onClickProSeriesMenu={openProSeriesMenu}
        onClickPSSeatsMenu={openPSSeatsMenu}
        onClickPSArmorMenu={openPSArmorMenu}
        onClickMainMenu={openMainMenu}
        onClickMenu={openMobileMenu}
        onClickLogIn={customerAccessToken ? goToAccount : openUserSlideout}
        navbarRightColumnLinks={menuData.navbarRightColumnLinks}
        module={module}
      />
      <Main>{children}</Main>
      <Footer {...footerProps} />
      <OpenKeyboard id="openMobileKeyboard" />
      <Backdrop
        isOpen={menu}
        onClick={closeMenu}
        topOffset={
          parseInt(DESKTOP_PROMO_HEIGHT) + parseInt(DESKTOP_HEADER_HEIGHT)
        }
      >
        <MenuWrapper
          ref={menuRef}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {menuState === DesktopMenuStates.Picker && (
            <CarSelector
              isOpen={menuState === DesktopMenuStates.Picker}
              resetPicker={resetPickerState}
              onResetPicker={onResetPickerState}
              onRequestCloseMenu={closeMenu}
              cars={menuData.cars}
              makeModelOrder={menuData.makeModelOrder}
              module={module}
            />
          )}
          {menuState === DesktopMenuStates.ProSeries && (
            <ProSeriesMenu
              proSeriesHeader={menuData.proSeriesHeader}
              proSeriesBody={menuData.proSeriesBody}
              proSeriesCategories={menuData.proSeriesCategories}
              onRequestCloseMenu={closeMenu}
              module={module}
            />
          )}
          {menuState === DesktopMenuStates.PSSeat && (
            <ProSeriesMenu
              proSeriesHeader={menuData.powersportsSeatHeader}
              proSeriesBody={menuData.powersportsSeatBody}
              proSeriesCategories={menuData.powersportsSeatCategories}
              onRequestCloseMenu={closeMenu}
              module={module}
              series="interior"
            />
          )}
          {menuState === DesktopMenuStates.PSArmor && (
            <ProSeriesMenu
              proSeriesHeader={menuData.powersportsArmorHeader}
              proSeriesBody={menuData.powersportsArmorBody}
              proSeriesCategories={menuData.powersportsArmorCategories}
              onRequestCloseMenu={closeMenu}
              module={module}
              series="exterior"
            />
          )}
          {menuState === DesktopMenuStates.FullMenu && (
            <MainMenu
              mainSectionLabel={menuData.mainSectionLabel}
              mainSectionLinks={menuData.mainSectionLinks}
              sideSectionLabel={menuData.sideSectionLabel}
              sideSectionLinks={menuData.sideSectionLinks}
              footerRightColumnLabel={menuData.footerRightColumnLabel}
              footerRightColumnLinks={menuData.footerRightColumnLinks}
              navbarRightColumnLinks={menuData.navbarRightColumnLinks}
              footerLeftColumnLabel={menuData.footerLeftColumnLabel}
              footerLeftColumnLinks={menuData.footerLeftColumnLinks}
              onRequestCloseMenu={closeMenu}
              module={module}
            />
          )}
        </MenuWrapper>
      </Backdrop>
      <MenuMobile
        isOpen={mobileMenu}
        data={menuData}
        module={module}
        menuStateOnOpen={mobileMenuState}
        onRequestClose={closeMobileMenu}
      />
      <Search
        isOpen={search}
        searchSuggestions={globalConfiguration?.searchSuggestions || []}
        onSearch={onSearch}
        onRequestClose={closeSearch}
      />
      <Cart isOpen={cart} onRequestClose={closeCart} />
      <CartToast
        onClickCart={openCart}
        checkoutId={checkoutId}
        items={items}
        total={total}
        subtotal={subtotal}
      />
      <UserSlideout isOpen={userSlideout} onRequestClose={closeUserSlideout} />
      <NewsletterForm
        isOpen={showNewsletter}
        title="Sign Up"
        onSubmit={() => {}}
        onClose={() => {
          setShowNewsletter(false)
        }}
      />
      {/* <CookieBannerContainer showBanner={showBanner}>
        <CookieBanner
          showBanner={showBanner}
          onAccept={acceptCookies}
          onDecline={declineCookies}
          {...cookieBannerProps}
        />
      </CookieBannerContainer> */}
    </Container>
  )
}

const mapState = (state: RootState) => ({
  customerAccessToken: state.customer.customerAccessToken,
  customerDataFetched: state.customer.customerDataFetched,
  checkoutDataFetched: state.checkout.checkoutDataFetched,
  customerData: state.customer.data,
  checkoutId: state.checkout.checkoutId,
  items: state.checkout.localCheckout?.items || [],
  subtotal: state.checkout.localCheckout?.subtotal || "",
  total: state.checkout.localCheckout?.total || "",
  productCount: selectCartQuantity(state),
})

const mapDispatch = (dispatch: Dispatch) => ({
  fetchCustomerData: dispatch.customer.fetchCustomerData,
  fetchCheckout: dispatch.checkout.fetchCheckout,
})

export default connect(mapState, mapDispatch)(Layout)
